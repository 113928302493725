import { mapState } from 'vuex';
import Badge from '@/components/Badge/Badge.vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';

const CURSOR_BEGINNING_INDEX = 0
const INVALID_INDEX = -1
const MINUS_SIGN_NUMBER = 1
const LIMITED_AMOUNT = 99990000;
const TAXATION = 1;
// const VALIDATE_OBJ_INDEX = 0;
const DEFAULT_ROW = {
	item_name: '',
	quantity: '',
	unit: '',
	unit_price: '',
	tax_classification: '',
	amount: '',
	quote_id: '',
	update_user: '',
};
export default {
	name: 'DetailTable',
	components: { Badge, DropDown, NotificationModal },
	props: {
		estimateDetails: {
			type: Array,
		},
		quoteId: {
			type: Number,
		},
		propUserId: {
			type: Number,
		},
		disabledAddRow: {
			type: Boolean,
		},
	},
	data() {
		return {
			userId: undefined,
			deletedItems: [],
			inputContents: [],
			selectiveModalId: 'remove-row-modal-id',
			selectiveContents: '',
			deleteRowId: undefined,
			/**
			 * @binding Tax classification options
			 */
			options: [
				{
					value: 1,
					text: this.$t('estimateScreen.detailTable.taxation'),
				},
				{
					value: 2,
					text: this.$t('estimateScreen.detailTable.taxExempt'),
				},
			],
		};
	},

	computed: {
		...mapState(['managementMasterInfo', 'projectInfo']),

		consumptionTaxRate() {
			if (this.managementMasterInfo)
				return this.managementMasterInfo['consumption_tax_rate'];
		},
		dropDownOption() {
			return this.options.map((option) => {
				return option.text;
			});
		},
		totalCalculation: function () {
			// let calculatedAmount = 0;
			let amountOfMoney = 0;
			let totalTax = 0;
			let subTotal = 0;
			let holdingTax = 0;
			let validAmount = true;

			if (this.consumptionTaxRate) {
				let taxRatio = this.consumptionTaxRate / 100;
				let ratioAfterTax = 1 + taxRatio;
				for (let i = 0; i < this.inputContents.length; i++) {
					let amountOfMoneyBeforeTax = Math.trunc(
						this.inputContents[i].quantity * this.inputContents[i].unit_price
					);
					if (this.inputContents[i].amount === 0) {
						this.inputContents[i].amount = null;
					}
					subTotal += amountOfMoneyBeforeTax;
					if (subTotal > LIMITED_AMOUNT) {
						// subTotal = null;
						validAmount = false;
					} else {
						if (this.inputContents[i].tax_classification === 1) {
							this._filterShowingAmount(i, amountOfMoneyBeforeTax, ratioAfterTax);
							amountOfMoney += Math.trunc(amountOfMoneyBeforeTax * ratioAfterTax);
							totalTax += Math.trunc(amountOfMoneyBeforeTax * taxRatio);
						} else if (this.inputContents[i].tax_classification === 2) {
							this._filterShowingAmount(i, amountOfMoneyBeforeTax, 1);
							amountOfMoney += amountOfMoneyBeforeTax;
							totalTax += 0;
						} else {
							this.inputContents[i].amount = null;
						}
						if (subTotal < 1000000) {
							holdingTax = Math.trunc((subTotal * 10.21) / 100);
						} else {
							holdingTax = Math.trunc(((subTotal - 1000000) * 20.42) / 100) + 102100;
						}
					}
				}

				let calculatedResult = {
					validAmount: validAmount,
					subTotal: subTotal,
					holdingTax: holdingTax,
					amountMoney: amountOfMoney,
					totalTax: totalTax,
				};
				return calculatedResult;
			}
		},
	},

	watch: {
		totalCalculation: function (newVal) {
			this.$emit('on-transfer-data', newVal);
		},

		inputContents: {
			handler: function (newVal) {
				let detailContent = newVal;
				this.$emit('on-transfer-detail-data', detailContent);
				this.$emit('on-transfer-input-status', {
					isValid: this._checkInputValid(this.inputContents),
					length: this.inputContents.length,
				});
			},
			deep: true,
		},
		/**
		 * Watch prop estimateDetails change
		 * assign to inputContents
		 * Including id property demonstrate estimateDetail is existed in Database
		 * @param {array} estimateDetails
		 */
		estimateDetails: function (estimateDetails) {
			if (estimateDetails.length > 0) {
				let contents = [];
				estimateDetails.map((estimateDetail) => {
					let content = {
						amount: parseFloat(estimateDetail['amount']),
						date_created: estimateDetail['date_created'],
						date_modified: estimateDetail['date_modified'],
						id: estimateDetail['id'],
						item_name: estimateDetail['item_name'],
						quantity: parseFloat(estimateDetail['quantity']),
						quote_id: estimateDetail['quote_id'],
						tax_classification: parseInt(estimateDetail['tax_classification']),
						unit: estimateDetail['unit'],
						unit_price: parseInt(estimateDetail['unit_price']),
						update_user: estimateDetail['update_user'],
						disabled: estimateDetail['disabled'],
					};
					contents.push(content);
				});
				this.inputContents = contents;
			}
		},

		propUserId(newVal) {
			this.userId = newVal;
		},
	},
	methods: {
		total(content) {
			let total = 0;
			total = parseFloat(content['quantity']) * parseInt(content['unit_price']);
			return isNaN(total) ? 0 : Math.trunc(total).toLocaleString('en-US');
		},

		/**
		 * Trigger when new row have been add
		 */
		addTableRow() {
			let addContent = {
				...DEFAULT_ROW,
				quote_id: this.quoteId,
				update_user: this.userId,
				tax_classification: TAXATION,
			};
			this.inputContents.push(addContent);
		},

		checkRequired(index) {
			return index < 1 ? true : false;
		},

		onDeleteRow(rowIdx) {
			this.deleteRowId = rowIdx;
			console.log(this.inputContents[rowIdx]);
			this.selectiveContents =
				'品目名' +
				'「' +
				this.inputContents[rowIdx].item_name +
				'」' +
				'を削除しますか？';
			this.$bvModal.show(this.selectiveModalId);
		},

		/**
		 * Trigger when one row have been delete
		 */
		deleteTableRow() {
			if (this.inputContents[this.deleteRowId].id) {
				this.deletedItems.push({ id: this.inputContents[this.deleteRowId].id });
				this.$emit('on-transfer-delete-items', this.deletedItems);
			}
			this.inputContents.splice(this.deleteRowId, 1);
		},

		_checkInputValid(inputContents) {
			//Finding Object in InputContents that their keys has empty value or not
			let checkItems = inputContents.find(
				(itemObj) =>
					itemObj['item_name'] === '' ||
					itemObj['quantity'] === '' ||
					itemObj['unit'] === '' ||
					itemObj['unit_price'] === '' ||
					itemObj['tax_classification'] === '' ||
					itemObj['unit_price'] === '-' ||
					isNaN(itemObj['unit_price'])
			);
			if (checkItems) {
				return false; // if checkItems is True → found Object inValid
			} else {
				return true; // if checkItems is undefined → all
			}
		},
		onSelectCategory(categoryInfo, inputContentIndex) {
			this.inputContents[inputContentIndex].tax_classification =
				categoryInfo.item == '課税' ? 1 : 2;
		},
		_filterShowingAmount(index, amount, taxValue) {
			this.inputContents[index]['amount'] = Math.floor(amount * taxValue);
		},

		onSelectFocus(index) {
			this.$refs.taxSelect[index].style.backgroundColor = '#d9d9d9';
		},

		onSelectBlur(index) {
			this.$refs.taxSelect[index].style.backgroundColor = '#f2f2f2';
		},

		onQuantityKeyDown(event, isFloat = false) {
			const acceptedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

			if (
				acceptedKeys.includes(event.code)
					? true
					: !isNaN(Number(event.key)) ||
					  (isFloat && event.key === '.' && !event.target.value.includes('.')) // eslint-disable-line no-mixed-spaces-and-tabs
			) {
				return;
			} else {
				event.preventDefault();
			}
		},

		onUnitPriceKeyDown(event) {
			const acceptedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '-'];
		
			if (
				acceptedKeys.includes(event.code) ||
				(!isNaN(Number(event.key)) && event.code !== 'Space') ||
				(event.key === '-' && event.target.selectionStart === CURSOR_BEGINNING_INDEX && event.target.value.indexOf('-') === INVALID_INDEX)
			) {
				return;
			} else {
				event.preventDefault();
			}
		},

		onUnitKeyDown(event) {
			if (!isNaN(Number(event.key))) {
				event.preventDefault();
			}
		},

		onUnitInput(event, index) {
			let value = event.target.value;
			value = value.replace(/[０-９0-9]/g, '');

			this.inputContents[index]['unit'] = value;
		},

		/**
		 * Convert Full-width to Haft-width Japanese characters
		 * @param {Integer} index item index
		 * @param {String} property content property
		 */
		onNumberInput(index, property) {
			let num = this.inputContents[index][property];
			if (!num) return;
			// Replace full-width characters with ASCII equivalents
			num = num.replace(/[０-９]/g, function (s) {
				return String.fromCharCode(s.charCodeAt(0) - 65248);
			});
			num = num.replace(/[．]/g, '.');
			// Add support for negative numbers
			if (num.startsWith('-')) {
				// Remove all "-" characters except the first one
				num = num.replace(/-/g, '');
				num = '-' + num;

				if (num.length === MINUS_SIGN_NUMBER) {
						this.inputContents[index][property] = '-';
						return;
				} else if (num.startsWith('--')) {
						// Prevent input of double minus sign
						this.inputContents[index][property] = num.substr(MINUS_SIGN_NUMBER);
						return;
				}

			}
			if (property !== 'quantity') {
					let numInt = parseInt(num);
					this.inputContents[index][property] = isNaN(numInt) ? null : numInt;
			} else {
					if (num.slice(-1) === '.') {
							this.inputContents[index][property] = num;
					} else {
							num = parseFloat(num);
							this.inputContents[index][property] = isNaN(num) ? null : num;
					}
			}
		}
},
	mounted() {
		let defaultRow = {
			...DEFAULT_ROW,
			quote_id: this.quoteId,
			update_user: this.userId,
			tax_classification: TAXATION,
		};
		this.inputContents.push(defaultRow);
	},
};
