import Badge from '@/components/Badge/Badge.vue';
import FunctionalCalendar from '@/components/DateRange/FunctionalCalendar.vue';

export default {
	components: { Badge, FunctionalCalendar },

	props: {
		inputKeyLabel: {
			type: String,
		},
		inputLabel: {
			type: String,
		},
		noteLabel: {
			type: String,
		},
		placeholder: {
			type: String,
		},
		isShowBadge: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
		},
		readonly: {
			type: Boolean,
		},
		inputRequired: {
			type: Boolean,
		},
		type: {
			type: String,
		},
		modalCustom: {
			type: Boolean,
			default: false,
		},
		modalRightCustom: {
			type: Boolean,
			default: false,
		},
		/**
		 * @binding Show input or not
		 * @value true: hidden
		 * @value false: visible
		 */
		hiddenInput: {
			type: Boolean,
		},
		toggleBorder: {
			type: Boolean,
		},
		toggleStyle: {
			type: Boolean,
		},
		inputPropContent: {
			type: [String, Date, Number],
		},
		maxlength: {
			type: Number,
		},
		checkBoxOptions: {
			type: Array,
		},
		caption: {
			type: String,
		},
		datalist: {
			type: Array,
			default() {
				return [];
			},
		},
	},

	data() {
		return {
			inputContent: undefined,
			accountType: undefined,
			inputDateContent: {
				selectedDate: '',
				dateRange: {
					start: '',
					end: '',
				},
			},
			numberInput: ['郵便番号', '電話番号'],
			isShowListGroup: true,
		};
	},

	watch: {
		inputContent: {
			handler(newVal) {
				let inputValue = newVal;
				if (this.inputKeyLabel === 'postal_code' && inputValue) {
					// Change from fullWidth to halfWidth
					this.inputContent = inputValue.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
						return String.fromCharCode(s.charCodeAt(0) - 65248);
					});
					let halfWidthNumber = this.inputContent;
					// Add hyphen after 3 numbers when input postal_code
					this.inputContent = halfWidthNumber.replace(/(\d{3})(\d{3})/, '$1-$2');
				} else if (this.inputKeyLabel === 'phone_number' && inputValue) {
					this.inputContent = inputValue.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
						return String.fromCharCode(s.charCodeAt(0) - 65248);
					});
				} else if (
					this.inputKeyLabel === 'account_number' &&
					typeof inputValue === 'string'
				) {
					this.inputContent = inputValue.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
						return String.fromCharCode(s.charCodeAt(0) - 65248);
					});
					if (this.inputContent > 7) {
						this.inputContent = this.inputContent.slice(0, 7);
					}
				} else if (
					this.inputKeyLabel === 'bank_name' ||
					this.inputKeyLabel === 'bank_branch'
				) {
					this.isShowListGroup = true;
				}

				this.$emit('on-input-change', {
					key: this.inputKeyLabel,
					val: inputValue,
				});
			},
		},

		'inputDateContent.selectedDate': {
			handler(newSelectedDate) {
				if (newSelectedDate) {
					this.$emit('on-input-change', {
						key: this.inputKeyLabel,
						val: newSelectedDate,
					});
				}
			},
			immediate: true,
		},

		accountType: {
			handler(newType) {
				if (newType) {
					console.log('NEW TYPE', newType);
					this.$emit('on-input-change', {
						key: this.inputKeyLabel,
						val: newType,
					});
				}
			},
		},

		/**
		 * Watch inputPropContent
		 * then view in date input
		 * @param {string} newInputPropContent
		 */
		inputPropContent: function (newInputPropContent) {
			if (newInputPropContent) {
				if (
					this.inputKeyLabel === 'issue_date' ||
					this.inputKeyLabel === 'expiry_date'
				) {
					this.inputDateContent.selectedDate = new Date(
						newInputPropContent
					).toDateString();
					return;
				} else if (this.inputKeyLabel === 'account_type') {
					this.accountType = newInputPropContent;
					return;
				}

				this.inputContent = newInputPropContent;
			}

			if (
				!newInputPropContent &&
				['bank_branch', 'address'].includes(this.inputKeyLabel)
			) {
				this.inputContent = newInputPropContent;
			}
		},
	},

	methods: {
		onInput() {
			if (this.inputKeyLabel === 'postal_code') {
				this.$emit('on-input-postal-code');
			}
		},

		onKeyDownDateInput(e) {
			if (e.key === 'Tab') return;
			e.preventDefault();
		},

		onInputFocus() {
			if (this.type === 'date') {
				this.$refs.dateInput.type = 'date';
			}
			this.$refs.dateInput.showPicker();
		},

		onInputFocusOut() {
			if (!this.inputContent && this.type === 'date') {
				this.$refs.dateInput.type = 'text';
			}
		},

		onNumberKeyDown(event) {
			const acceptedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
			if (
				acceptedKeys.includes(event.code)
					? true
					: !isNaN(Number(event.key)) && event.code !== 'Space'
			) {
				return;
			} else {
				event.preventDefault();
			}
		},

		onListGroupClickOutSide() {
			this.isShowListGroup = false;
		},

		onOptionClick(index, item) {
			this.$emit('on-select-option', item);

			setTimeout(() => {
				this.isShowListGroup = false;
			}, 100);
		},

		onInputClick() {
			if (this.inputKeyLabel === 'bank_branch') {
				this.$emit('on-input-click');
				if (!this.inputContent) {
					setTimeout(() => {
						this.isShowListGroup = true;
					}, 100);
				}
			}
		},
	},
	mounted() {
		const now = new Date();
		// Init default data value
		if (!this.inputPropContent && this.inputKeyLabel === 'issue_date')
			this.inputDateContent.selectedDate = now;
		else if (!this.inputPropContent && this.inputKeyLabel === 'expiry_date')
			this.inputDateContent.selectedDate = new Date(now.setDate(now.getDate() + 30));
	},
};
