import moment from 'moment';
import Axios from 'axios';

export default {
	filters: {
		filterHistoryDate(value) {
			if (value && typeof value === 'string') {
				let momentDate = moment(value);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');
				let hour = momentDate.format('HH:mm');

				return `御見積書［${year}年${month}月${day}日${hour} 送信］`;
			}
			return value;
		},
	},
	methods: {
		async onDownload() {
			this.$store.commit('setIsAppProcessing', true);

			if (!this.pdfFileUrl) {
				this.$store.commit('setIsAppProcessing', false);
				return;
			}
			this._getDefaultFileName(this.projectInfo, this.clientInfo);
			// Using Axios get PDF file to browser and rename it before downloading
			// Reason: Can not rename PDF file directly by external URL just using <a> tag
			try {
				let pdfFile = await Axios.get(this.pdfFileUrl, {
					headers: {
						'Content-Type': 'application/octet-stream',
					},
					responseType: 'blob',
				});
				let aTag = document.createElement('a');
				let url = window.URL.createObjectURL(pdfFile.data);
				aTag.href = url;
				aTag.download = this.defaultFileName;
				aTag.click();

				this.$store.commit('setIsAppProcessing', false);
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		_getDefaultFileName(projectInfo, clientInfo) {
			console.log(clientInfo);
			let downloadDateTime = moment().format('YYYYMMDDHHmmss');
			this.defaultFileName =
				clientInfo['client_name'] +
				'様_' +
				projectInfo['project_name'] +
				'_見積書_' +
				downloadDateTime +
				'.pdf';
		},
	},
};
