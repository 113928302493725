import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import socketClient from '@/services/SOCKET';
import HxPdfExporter from '@/components/HxPdfExporter/HxPdfExporter.vue';
import EstimateParticularService from '@/services/API/estimate_particular.service';
import EstimateDetailService from '@/services/API/estimate_detail.service';
import DecisionService from '@/services/API/decision.service';
import Modal from '@/components/Modal/Modal.vue';
import Badge from '@/components/Badge/StatusBadge.vue';

import TransferService from '@/services/API/transfer.service';
import { sendNotify, toLineMessageTemplate } from '@/utilities';

import CommonConst from '@/constants/CommonConst';
import EstimateConst from '@/constants/EstimateConst';
import EstimateMixin from '@/mixin/estimateMixin';

import moment from 'moment';
import Axios from 'axios';
import { mapState } from 'vuex';

export default {
	name: 'EstimateMainScreen',
	mixins: [EstimateMixin],
	components: {
		ScreenHeader,
		ProjectDeadline,
		NotificationModal,

		HxPdfExporter,
		BottomButton,
		Modal,
		Badge,
	},
	props: {
		pdfData: {
			type: Object,
		},
		quotationError: {
			type: Boolean,
		},
		pdfImageUrl: {
			type: String,
		},
		pdfFileUrl: {
			type: String,
		},
		/**
		 * @binding Estimate Status
		 * @value 'onSave': When Quotation has been saved
		 * @value CommonConst.SCREEN_STATUS.WAITING_CONFIRM: When Confirm Button has been clicked
		 * @value 'onDecline': When client has been decline
		 * @value CommonConst.SCREEN_STATUS.ACCEPT
		 * @value CommonConst.SCREEN_STATUS.FINISH
		 */
		estimateStatus: {
			type: String,
		},
		mobileLiveScreenMinimum: Boolean,
	},
	data() {
		return {
			middleContent: ' 様 ／ ',
			screenIcon: 'estimate-icon',
			screenName: '見積書作成',
			selectiveContents: EstimateConst.SELECTIVE_NOTI_CONTENTS,
			plainContents: EstimateConst.PLAIN_NOTI_CONTENTS,
			errorContents: EstimateConst.ERROR_CONTENTS,
			confirmWaitingText: EstimateConst.CONFIRM_WAITING_TEXT,
			retryText: EstimateConst.RETRY_TEXT,
			// TODO: Handle process status
			showStatusBadge: false,
			isRetryReason: false,
			// Array of DateTime
			retryHistories: [],
			retryReasonContent: '',
			companyAddress: '',
			total: '',
			issueDate: '',
			postNumber: '',
			showButton: false,
			showEditButton: false,
			buttonType: 'single-btn',
			buttonName: '送 信',
			showDownloadButton: false,
			defaultFileName: '',
			pdf: '',
			isSaveButtonClicked: false,
			pdfFileData: '',
			selectiveModalId: 'estimate-selective-modal',
			plainModalId: 'estimate-plain-modal',
			errorModalId: 'esimate-main-error-modal',
			directLink: 'decision',
			isPdfModalOpened: false,
			estimateParticularId: undefined,
			badgeContent: '',
			badgeType: '',
			mailContent: '',
			historyPdfData: {},
			isSearchIconShow: false,
			pdfPreviewer: {},
			mailTitle: '',
			subject: '',
			isSendCreator: false,
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
		]),
		isRetryHistory() {
			return (
				this.retryHistories.length > 0 &&
				(this.estimateStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM ||
					this.estimateStatus === CommonConst.SCREEN_STATUS.ACCEPT ||
					this.estimateStatus === CommonConst.SCREEN_STATUS.FINISH)
			);
		},
		destinations() {
			return [
				//replace with your email for testing
				this.clientInfo['email_address'],
				this.creatorInfo['email_address'],
			];
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}estimate`
						: `${this.projectInfo['process_client_url']}estimate`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}estimate`
					: `${this.projectInfo['process_client_url']}estimate`,
			});
		},
		/* eslint-disable no-mixed-spaces-and-tabs */
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},
	watch: {
		projectInfo: {
			handler: function (projectInfo, clientInfo) {
				this._getDefaultFileName(projectInfo, clientInfo);
			},
			deep: true,
		},

		pdfData: {
			handler: function (pdfData) {
				if (pdfData.estimateParticular && pdfData.estimateParticular.id) {
					this.estimateParticularId = pdfData.estimateParticular['id'];
					this.retryReasonContent = pdfData.estimateParticular['retry_reason'];
					this.isSaveButtonClicked = true;
				}
				this.projectName = pdfData.estimateParticular['project_name'];
			},
			deep: true,
		},

		estimateStatus: {
			handler: function (newStatus) {
				this._checkEstimateStatus();

				// Update notification red-dot base on screen status
				if (
					newStatus === CommonConst.SCREEN_STATUS.ACCEPT ||
					newStatus === CommonConst.SCREEN_STATUS.DECLINE
				) {
					this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.ESTIMATE]);
				} else {
					const redDotIndexs = this.redDotIndexs.filter(
						(screenId) => screenId !== CommonConst.SCREEN_ID.ESTIMATE
					);
					this.$store.commit('setRedDotIndexs', redDotIndexs);
				}
			},
			immidiate: true,
		},
	},

	methods: {
		onEditButtonClick() {
			this.$emit('on-edit-btn-click', false);
			this.showStatusBadge = false;
		},

		async onDirectButtonClick() {
			this.$store.commit('setIsAppProcessing', true);

			await this._createNewDecision();

			this.$emit('on-change-estimate-status', CommonConst.SCREEN_STATUS.FINISH);
			await this._updateEstimateParticularStatus(
				this.estimateParticularId,
				CommonConst.SCREEN_STATUS.FINISH
			);

			let confirmInfo = {
				screenId: CommonConst.SCREEN_ID.ESTIMATE,
				confirmInfo: true,
			};
			// send mail case 24
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「詳細決め」スタート`;
			this.mailContent = `「詳細決め」を\nスタートします。`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「詳細決め」スタート`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']}「詳細決め」スタート`;
			this.mailContent = `「詳細決め」を\nスタートします。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「詳細決め」スタート`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$refs.hxPdfExporterOrderConfirm._renderTemplateString();

			this.$emit('on-send-confirm-info', confirmInfo);

			// Send event
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'estimate-direct',
				},
				room: this.projectId,
			});

			this.$store.commit('setIsAppProcessing', false);
			this.$router.push(this.directLink);
		},

		onEstimateBottomButtonClick() {
			if (this.isSaveButtonClicked) {
				this.$bvModal.show(`${this.selectiveModalId}`);
			} else {
				this.errorContents = EstimateConst.ERROR_CONTENTS;
				this.$bvModal.show('esimate-main-error-modal');
			}
		},

		/**
		 * Down load history Estimate PDF file
		 * @param {String} pdfFileKey file key from S3
		 * @param {String} confirmTime Confirm time
		 */
		async onHistoryClick(pdfFileKey, confirmTime) {
			this.$store.commit('setIsAppProcessing', true);

			if (!pdfFileKey) {
				this.$store.commit('setIsAppProcessing', false);

				return;
			}

			let pdfFileUrl = await this._getPreviewPDFUrl(
				pdfFileKey,
				CommonConst.ONE_WEEK_SECONDS
			);

			let downloadDate = moment(confirmTime).format('YYYYMMDDHHmmss');
			let downloadFileName =
				this.clientInfo['client_name'] +
				'様_' +
				this.projectInfo['project_name'] +
				'_見積書_' +
				downloadDate +
				'.pdf';
			// Using Axios get PDF file to browser and rename it before downloading
			// Reason: Can not rename PDF file directly by external URL just using <a> tag
			try {
				let pdfFile = await Axios.get(pdfFileUrl, {
					headers: {
						'Content-Type': 'application/octet-stream',
					},
					responseType: 'blob',
				});
				let aTag = document.createElement('a');
				let url = window.URL.createObjectURL(pdfFile.data);
				aTag.href = url;
				aTag.download = downloadFileName;
				aTag.click();

				this.$store.commit('setIsAppProcessing', false);
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 * Get file preview url from S3 Service
		 * @param {String} key file key from s3 service
		 * @param {Integer} expiration time of existence
		 * @returns File previrw url
		 */
		async _getPreviewPDFUrl(key, expiration) {
			try {
				let response = await TransferService.get(key, expiration);
				if (!response || response.status !== 200) {
					throw 'Get PDF url failed!';
				}

				console.log('%c Get PDF url successfully!', 'color: red');
				let url = response.data.link;
				console.log({ url });
				return url;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Render Estimate Template displayed in EstimateScreen
		 */
		async onRenderEstimateTemplate() {
			console.log('On render quotation');
			let pdf = await this.$refs.hxPdfExporter.getRenderedPDFTemplate();
			let pdfFileParams = {
				content: pdf,
				project_id: this.projectId,
				file_name: 'estimate' + new Date().getTime().toString() + '.pdf',
			};
			let pdfFileKey = await this._uploadPdf(pdfFileParams);
			this.$emit('on-transfer-uploaded-file', { pdfFileKey });

			// Finish render PDF
			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Render Order Confirm
		 */
		async onRenderOrderConfirm() {
			console.log('On render Order Confirm');
			let pdf = await this.$refs.hxPdfExporterOrderConfirm.getRenderedPDFTemplate();
			let pdfFileParams = {
				content: pdf,
				project_id: this.projectId,
				file_name: 'order-confirm.pdf',
			};
			let orderConfirmFileKey = await this._uploadPdf(pdfFileParams);
			await this._updateEstimateParticular(this.estimateParticularId, {
				order_confirm_file_key: [orderConfirmFileKey],
			});
		},

		_renderTempateString() {
			this.$refs.hxPdfExporter._renderTemplateString();
		},

		async onSendConfirmRequire() {
			this.$store.commit('setIsAppProcessing', true);

			this.$emit(
				'on-change-estimate-status',
				CommonConst.SCREEN_STATUS.WAITING_CONFIRM
			);
			await this._updateEstimateParticularStatus(
				this.estimateParticularId,
				CommonConst.SCREEN_STATUS.WAITING_CONFIRM
			);

			await this._updateEstimateParticularConfirmTime(this.estimateParticularId);

			// Send event
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'estimate_confirm_required',
				},
				room: this.projectId,
			});

			//send mail to creator case 18
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「お見積り」を送信しました`;
			this.mailContent = `「お見積り」を\n送信しました`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「お見積り」を送信しました`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']}「お見積り」が届きました`;
			this.mailContent = `「お見積り」が\n届きました。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 「お見積り」が届きました`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			await this._getAllEstimateParams(this.projectId);
			this.$emit('on-refresh-data');

			this.$store.commit('setIsAppProcessing', false);
		},

		onPDFClick() {
			this.pdfPreviewer.url = this.pdfFileUrl;
			this.$bvModal.show(`preview-pdf-modal`);
			this.isPdfModalOpened = true;
		},

		_disableElements() {
			this.showButton = false;
			this.showEditButton = false;
		},

		/**
		 *
		 * @param {Integer} projectId Project ID
		 * @returns Araa
		 */
		async _getAllEstimateParams(projectId) {
			try {
				let response = await EstimateParticularService.getAll(projectId);
				if (!response || response.status !== 200) {
					throw 'Get estimate particuler by project failed!';
				}
				console.log(
					'%c Get estimate particular by project successfully!',
					'color: green'
				);
				let estimateParticulars = [...response.data];
				// Remove current estimate particular
				estimateParticulars.pop();
				this.retryHistories = estimateParticulars;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get Estimate Particular And Children records
		 * @param {integer} estimateParticularId : Estimate Particular Id
		 */
		async _getEstimateParticularAndChildrenById(estimateParticularId) {
			try {
				let response = await EstimateParticularService.getById(estimateParticularId);

				if (response && response.status === 200) {
					let data = response.data;

					let estimateDetails = data['estimate_details'].sort((previous, current) => {
						if (previous.id < current.id) return -1;
						if (previous.id > current.id) return 1;
						return 0;
					});
					let projectName = {
						project_name: this.projectInfo['project_name'],
					};

					console.log('Get estimate particular successfully');
					return {
						estimateParticular: { ...data['estimate_particular'], ...projectName },
						estimateDetails: estimateDetails,
					};
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(`Get estimate particular failed: ${error}`);
			}
		},

		async _updateEstimateParticular(estimateParticularId, body) {
			try {
				let response = await EstimateParticularService.update(estimateParticularId, {
					doc_content: body,
				});
				if (response && response.status === 200) {
					console.log('Update Estimate particular successfully!');
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		async _updateEstimateParticularConfirmTime(estimateParticularId) {
			try {
				let response = await EstimateParticularService.update(estimateParticularId, {
					doc_content: { confirm_time: new Date() },
				});
				if (response && response.status === 200) {
					console.log(
						'%c Update Estimate particular confirm_time successfully!',
						'color: green'
					);
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		async _updateEstimateParticularStatus(estimateParticularId, status) {
			try {
				let response = await EstimateParticularService.update(estimateParticularId, {
					doc_content: { status: status },
				});
				if (response && response.status === 200) {
					console.log(
						'%c Update Estimate particular status successfully!',
						'color: green'
					);
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		async _uploadPdf(params) {
			try {
				let response = await TransferService.postPdf(params);
				if (!response || response.status !== 200) {
					throw 'Upload PDF failed!';
				}

				console.log('%c Upload PDF successfully!', 'color: red');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Create new Estimate Particular record
		 * @param {Object} estimateParticular estimateParticular Object
		 */
		async _createNewEstimateParticular(estimateParticular) {
			let response = await EstimateParticularService.post({
				doc_content: estimateParticular,
			});

			if (response && response.status === 200) {
				let estimateParticularId = response.data.id;
				this.estimateParticularId = estimateParticularId;
				console.log('Create Estimate particular successfully!');
			} else {
				throw response.message;
			}
		},

		/**
		 * Create bulk Estimate Detail records
		 * @param {Array} estimateDetails Array of estimmateDetails Object
		 */
		async _createBulkEstimateDetails(estimateDetails, quoteId) {
			let bodyContents = [...estimateDetails];

			bodyContents.map((bodyContent) => {
				bodyContent['quote_id'] = quoteId;
				bodyContent['update_user'] = this.userId;
			});
			let response = await EstimateDetailService.postBulk({
				doc_content: bodyContents,
			});
			if (response && response.status === 200) {
				console.log('Create Bulk Estimate details successfully!');
			} else {
				throw response.message;
			}
		},

		_checkEstimateStatus() {
			console.log('%c CHECK STATUS', 'color: red', this.estimateStatus);
			switch (this.estimateStatus) {
				case CommonConst.SCREEN_STATUS.SAVE:
					this.showButton = true;
					this.showEditButton = true;
					this.showDownloadButton = true;
					this.showStatusBadge = false;
					break;

				case CommonConst.SCREEN_STATUS.WAITING_CONFIRM:
					this.showStatusBadge = true;
					this.showButton = false;
					this.showEditButton = false;
					this.showDownloadButton = true;
					this.isRetryReason = false;
					this.badgeContent = EstimateConst.CONFIRM_WAITING_TEXT;
					break;

				case CommonConst.SCREEN_STATUS.DECLINE:
					this.showDownloadButton = true;
					this.isRetryReason = true;
					this.showButton = false;
					this.showEditButton = true;
					break;

				case CommonConst.SCREEN_STATUS.ACCEPT:
					this.buttonType = 'direct-screen-btn';
					this.buttonName = '詳細決めに進む';
					this.showButton = true;
					this.showEditButton = false;
					this.showStatusBadge = false;
					break;

				case CommonConst.SCREEN_STATUS.FINISH:
					this.showStatusBadge = false;
					this.showButton = false;
					this.showEditButton = false;
					this.showDownloadButton = true;
					this.isRetryReason = false;
					break;

				default:
					break;
			}
		},

		/**
		 * Create new Decision record
		 */
		async _createNewDecision() {
			let decisionData = {
				project_id: this.projectId,
				image_list: [],
				update_user: this.userId,
			};

			let response = await DecisionService.post({
				doc_content: decisionData,
			});

			if (!response || response.status !== 200) {
				throw 'Create Decision failed';
			}

			console.log('%c Create Decision successfully', 'color: green');
		},
	},

	async created() {
		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'estimate_retrying_reason':
					this.showStatusBadge = true;
					this.badgeType = 'warning';
					this.badgeContent = EstimateConst.RETRY_NOTI_CONTENT;
					this.$emit('on-change-estimate-status', data?.content?.estimateStatus);
					this.retryReasonContent = data?.content?.retryReason;
					break;
				default:
					break;
			}
		});
	},

	async mounted() {
		moment.locale('ja');
		await this._getAllEstimateParams(this.projectId);
	},
};
