<template>
	<span class="requiredText">
		{{ badgeText }}
	</span>
</template>

<script>
export default {
	name: 'Badge',
	data() {
		return {
			badgeText: '「必須」',
		};
	},
};
</script>
