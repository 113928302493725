import EstimateMainScreen from './EstimateMainScreen/EstimateMainScreen.vue';
import QuotationScreen from './QuotationScreen/QuotationScreen.vue';
import EstimateParticularService from '@/services/API/estimate_particular.service';
import TransferService from '@/services/API/transfer.service';
import ScheduleService from '@/services/API/schedule.service';
import socketClient from '@/services/SOCKET';

import { mapState } from 'vuex';
const ESTIMATE_SCREEN_ID = 2;

export default {
	components: {
		EstimateMainScreen,
		QuotationScreen,
	},
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	data() {
		return {
			quotationError: false,
			isQuotationShow: true,
			isShowContent: false,
			estimateParticularId: undefined,
			originEstimateData: {
				estimateParticular: {},
				estimateDetails: [],
			},
			pdfData: {},
			pdfFileKey: '',
			pdfImageKey: '',
			defaultFilePath: 'default-template.jpeg',
			expiration: '86400',
			pdfImageUrl: '',
			pdfFileUrl: '',
			estimateStatus: '',
			scheduleEstimateHour: [],
		};
	},
	computed: {
		// Get userId from store
		...mapState(['userId', 'clientInfo', 'projectId', 'projectInfo']),
	},
	methods: {
		// onPreviewButtonClick(data) {
		// 	this.isQuotationShow = data;
		// },
		onEditButtonClick(data) {
			this.isQuotationShow = !data;
		},

		async onUploadedFile(key) {
			if (!this.estimateParticularId) return;

			this.pdfFileKey = key.pdfFileKey;
			let params = {
				pdf_file_key: this.pdfFileKey,
			};
			await this._updateEstimateParticular(this.estimateParticularId, params);
			// await this._getPdfImageUrl(this.pdfImageKey);
			await this._getPdfFileUrl(this.pdfFileKey);
		},

		async onUploadInvoiceFile(key) {
			this.pdfFileKey = key.pdfFileKey;
			let params = {
				pdf_invoice_file_key: this.pdfFileKey,
			};
			await this._updateEstimateParticular(this.estimateParticularId, params);
		},

		async onSaveButtonClick(data) {
			this.isQuotationShow = !data.isShowQuotation;
			await this._getEstimateParticularAndChildren(this.projectId);
			this.pdfData = this.originEstimateData;
			// Start render PDF file
			this.$refs.esimateMainScreen._renderTempateString();
		},

		onGetConfirmInfo(data) {
			this.$emit('on-send-confirm-info', data);
			this.$emit('on-update-project-status', ESTIMATE_SCREEN_ID + 1);
		},

		async onRefreshData() {
			await this._getEstimateParticularAndChildren(this.projectId);
		},

		/**
		 * Get Estimate Particular And Children records
		 * @param {integer} projectId : project id
		 */
		async _getEstimateParticularAndChildren(projectId) {
			try {
				let response = await EstimateParticularService.get(projectId);

				if (response && response.status === 200) {
					console.log('Get estimate particular successfully');
					let data = response.data;
					let estimateDetails = data['estimate_details'].sort((previous, current) => {
						if (previous.id < current.id) return -1;
						if (previous.id > current.id) return 1;
						return 0;
					});

					let estimateParticular = data['estimate_particular'];

					let projectName = {
						project_name: this.projectInfo['project_name'],
					};
					this.originEstimateData.estimateParticular = {
						...estimateParticular,
						...projectName,
					};
					this.originEstimateData.estimateDetails = estimateDetails;
					this.pdfData = { ...this.originEstimateData };
					this.estimateParticularId = estimateParticular['id'];
					this.pdfImageKey = estimateParticular['pdf_image_key'];
					this.pdfFileKey = estimateParticular['pdf_file_key'];
					this.estimateStatus = estimateParticular['status'];

					if (this.estimateStatus) {
						this.isQuotationShow = false;
					} else {
						this.isQuotationShow = true;
					}

					this.isShowContent = true;
				} else {
					console.log('Get estimate particular failed');
				}
			} catch (error) {
				console.log(`Get estimate particular failed: ${error}`);
			}
		},

		importScheduleEstimateHours(quotationDatas) {
			let scheduleEstimateHour = [...this.scheduleEstimateHour];
			// remove element if name, quantity or unit_price is empty when impport from schedule
			let addedDetails = quotationDatas.estimateDetails;
			let commentContents = quotationDatas.comment;
			let companyAddress = quotationDatas.companyAddress;
			let estimateDetails = [
				...addedDetails.filter(
					(detail) =>
						detail.item_name !== '' &&
						detail.unit_price !== '' &&
						detail.quantity !== ''
				),
			];
			if (scheduleEstimateHour.length > 0 && this.projectInfo['cost'] !== null) {
				scheduleEstimateHour.map((ele) => {
					estimateDetails.push({
						item_name: ele.processName,
						tax_classification: 1,
						unit: '時間',
						quantity: ele.estimatedHour,
						unit_price: this.projectInfo['cost'],
					});
				});
			}
			this.originEstimateData.estimateDetails = estimateDetails;
			this.originEstimateData.estimateParticular['comment'] = commentContents;
			this.originEstimateData.estimateParticular['address'] = companyAddress;
		},

		changeEstimateStatus(status) {
			this.estimateStatus = status;
			// Show download screen button
			if (status === 'onAccept') {
				this.$emit('on-show-download-screen-button');
			}
		},

		async _getPreviewPDFUrl(key, expiration) {
			try {
				let response = await TransferService.get(key, expiration);
				if (!response || response.status !== 200) {
					throw 'Get PDF url failed!';
				}

				console.log('%c Get PDF url successfully!', 'color: red');
				let url = response.data.link;
				return url;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get estimate data
		 * Include: estimateParticular, EstimateDetails and projectName
		 */
		async _getEstimateData() {
			// Check if current project id existed
			if (this.projectId) {
				await this._getEstimateParticularAndChildren(this.projectId);
				await this._getPdfFileUrl(this.pdfFileKey);
			}
		},

		async _getPdfFileUrl(pdfFileKey) {
			if (pdfFileKey) {
				let pdfFileUrl = await this._getPreviewPDFUrl(pdfFileKey, this.expiration);
				this.pdfFileUrl = pdfFileUrl;
			}
		},

		async _updateEstimateParticular(estimateParticularId, body) {
			console.log('%c Update particular estimate screen', 'color: green');
			let response = await EstimateParticularService.update(estimateParticularId, {
				doc_content: body,
			});
			if (response && response.status === 200) {
				console.log('Update Estimate particular successfully!');
			} else {
				throw response.message;
			}
		},

		async getScheduleInfo(projectId) {
			let response = await ScheduleService.get(projectId);
			if (response && response.status === 200) {
				console.log('Get schedule successfully');
				let data = response.data;
				this.scheduleInfo = data;
				this.$store.commit('setSchedule', data);
				const estimatedHourInfo = [
					data['estimate_estimate_hour'],
					data['decision_estimate_hour'],
					data['creative_estimate_hour'],
					data['proposal_estimate_hour'],
					data['delivery_estimate_hour'],
				];
				if (estimatedHourInfo.includes(null)) {
					this.scheduleEstimateHour = [];
				} else {
					this.scheduleEstimateHour = [
						{
							processName: '見積書作成',
							estimatedHour: data['estimate_estimate_hour'],
						},
						{
							processName: '詳細決め',
							estimatedHour: data['decision_estimate_hour'],
						},
						{
							processName: 'クリエイティブ',
							estimatedHour: data['creative_estimate_hour'],
						},
						{
							processName: 'ご提案',
							estimatedHour: data['proposal_estimate_hour'],
						},
						{
							processName: '納品',
							estimatedHour: data['delivery_estimate_hour'],
						},
					];
				}
			} else {
				console.log('Get schedule failed');
			}
		},
	},
	async created() {
		socketClient.listen('new_estimate_client_confirm', () => {
			this.changeEstimateStatus('onAccept');
		});
	},
	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		await this.getScheduleInfo(this.projectId);
		await this._getEstimateData();

		this.$store.commit('setIsAppProcessing', false);
	},
};
