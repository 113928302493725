import ApiService from './api.service';

const serviceURL = 'estimate_detail';
const EstimateService = {

  /**
   * Post bulk Estimate Detail records
   * @param {Object} body {doc_content: [estimateDetails Array]}
   * @returns {Object} Success message
   */
	postBulk(body) {
		return ApiService.post(`${serviceURL}/bulk`, body);
  },
  
  /**
   * Update bulk Estimate Detail records
   * @param {Object} body {doc_content: [estimateDetails Array]}
   * @returns {Object} Success message
   */
  updateBulk(body) {
    return ApiService.put(`${serviceURL}/bulk`, body);
  },

  /**
   * Delete bulk Estimate Detail records
   * @param {Object} body {doc_content: [estimateDetailIds Array]}
   * @returns {Object} Success message
   */
   deleteBulk(body) {
    return ApiService.deleteBulk(`${serviceURL}/bulk`, body);
  }
};

export default EstimateService;
