export default {
	name: 'DropDown',
	props: {
		dropDownTitle: {
			type: String,
		},
		dropDownItems: {
			type: Array,
		},
		default: {
			type: String,
		},
		tabindex: {
			type: Number,
		},
		dropDownIdx: {
			type: Number,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selected: this.default,
			open: false,
		};
	},
	watch: {
		default(newVal) {
			this.selected = newVal;
		},
	},
	methods: {
		onChange(item, idx) {
			this.selected = item;
			const transferData = {
				item: item,
				idx: idx,
			};
			this.$emit('on-change', transferData);
		},

		/**
		 * Handle hide dropdown's options when click outside the dropdown
		 */
		onBlur() {
			console.log('BLUR');
		},
	},
};
