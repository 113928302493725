import { checkMobileScreen } from '@/store/modules/ScreenWidth';

const SELECTIVE_NOTI_CONTENTS = checkMobileScreen()
	? '「 御見積書 」 をクライアントに\n送信します'
	: '「 御見積書 」 をクライアントに送信します';
const PLAIN_NOTI_CONTENTS =
	'クライアントに通知しました\nクライアントが承認するまでお待ちください\n承認されたらご登録のメールに通知されます';
const ERROR_CONTENTS = '見積りを作成してください。';
const CONFIRM_WAITING_TEXT = 'クライアント承認待ち';
const RETRY_TEXT = '再見積り依頼中';
const RETRY_NOTI_CONTENT = 'クライアントが承認されませんでした';

const NO_DATA_TEXT = '作成中';
const SELECTIVE_CONTENT = '「 見積書作成 」を承認しますか？';
const REASON_SENT_CONTENTS = '再見積り依頼を送信しました';
const CONFIRM_SENT_CONTENTS = '「 詳細決め 」に進みます';

export default {
	SELECTIVE_NOTI_CONTENTS,
	PLAIN_NOTI_CONTENTS,
	ERROR_CONTENTS,
	CONFIRM_WAITING_TEXT,
	RETRY_TEXT,
	RETRY_NOTI_CONTENT,
	NO_DATA_TEXT,
	SELECTIVE_CONTENT,
	REASON_SENT_CONTENTS,
	CONFIRM_SENT_CONTENTS,
};
